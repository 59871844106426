<template>
    <section>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-alert
                        v-height-fade.appear
                        :show="validationErrors.length > 0"
                        variant="danger"
                        dismissible
                    >
                        <div
                            class="alert-body"
                            v-for="(validationError, index) in validationErrors"
                            :key="index"
                        >
                            <small>{{ validationError }}</small>
                        </div>
                    </b-alert>
                    <h3 class="">Edit Post</h3>
                    <validation-observer ref="editPostForm">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="updatePostHandler"
                        >
                            <div class="row">
                                 <div class="col-md-6">
                                    <b-form-group 
                                        label="author"
                                        label-for="author"
                                    >
                                            <b-form-select 
                                                id="author"
                                                name="author_id" 
                                                value-field="id"
                                                text-field="username"
                                                v-model="authorId" 
                                                :options="admins"
                                            />
                                    </b-form-group>
                                 </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Title"
                                        label-for="title"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="title"
                                            vid="title"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="title"
                                                v-model="title"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                name="title"
                                                placeholder="Enter post title"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Featured Image"
                                        label-for="featuredImage"
                                    >
                                        <div class="d-flex flex-row">
                                            <!-- featured image preview -->
                                            <b-img
                                                v-if="featuredImage.preview"
                                                :src="featuredImage.preview"
                                                width="37px"
                                                height="37px"
                                                alt=""
                                                class="
                                                    rounded
                                                    border-black
                                                    mr-50
                                                "
                                            />
                                            <!-- featured image preview -->
                                            <b-form-file
                                                id="featuredImage"
                                                @change="
                                                    uploadFileHandler(
                                                        $event,
                                                        'featured_image'
                                                    )
                                                "
                                                placeholder="Choose featured image"
                                                ref="featured-image"
                                                drop-placeholder="Drop it"
                                            />
                                        </div>
                                        <!-- featured image remove handler -->
                                        <b-button
                                            v-if="featuredImage.preview"
                                            class="mt-25"
                                            @click="removeFeaturedImage"
                                            v-ripple.400="
                                                'rgba(234, 84, 85, 0.15)'
                                            "
                                            variant="flat-danger"
                                            size="sm"
                                        >
                                            remove
                                        </b-button>
                                        <!-- featured image remove handler -->
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Description"
                                        label-for="description"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="description"
                                            vid="description"
                                            rules="required"
                                        >
                                            <quill-editor
                                                id="body"
                                                name="description"
                                                v-model="description"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                :options="editorOption"
                                                @ready="onEditorReady($event)"
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group label="SPL" label-for="spl">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="spl"
                                            vid="spl"
                                            rules="required"
                                        >
                                            <b-form-textarea
                                                id="spl"
                                                rows="3"
                                                name="spl"
                                                v-model="spl"
                                                placeholder="Enter post spl"
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                            />
                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group label="Tags" label-for="tags">
                                        <b-form-tags
                                            v-model="tags"
                                            id="tags"
                                            name="tags"
                                            remove-on-delete
                                            placeholder="Add..."
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Reference"
                                        label-for="reference"
                                    >
                                        <b-form-input
                                            type="url"
                                            id="reference"
                                            name="reference"
                                            v-model="reference"
                                            placeholder="Enter post reference"
                                        />
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Version"
                                        label-for="version"
                                    >
                                        <b-form-input
                                            type="number"
                                            id="version"
                                            name="version"
                                            v-model="version"
                                            placeholder="Enter post version"
                                            min="1"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Security Journey"
                                        label-for="security-journey"
                                    >
                                        <v-select
                                            multiple
                                            v-model="securityJourneyValue"
                                            name="security_journey[]"
                                            id="security-journey"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="securityJourneyOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Security Use Case Applicability"
                                        label-for="security-use-case-applicability"
                                    >
                                        <v-select
                                            multiple
                                            v-model="
                                                securityUseCaseApplicabilityValue
                                            "
                                            name="security_use_case_applicability[]"
                                            id="security-use-case-applicability"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="
                                                securityUseCaseApplicabilityOptions
                                            "
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Data Models"
                                        label-for="data-models"
                                    >
                                        <v-select
                                            multiple
                                            v-model="dataModelsValue"
                                            name="data_models[]"
                                            id="data-models"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="dataModelsOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="lookup"
                                        label-for="lookup"
                                    >
                                        <b-form-tags
                                            v-model="lookup"
                                            id="lookup"
                                            name="lookup"
                                            placeholder="Add..."
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Source Type"
                                        label-for="source-type"
                                    >
                                        <b-form-input
                                            id="source-type"
                                            name="source_type"
                                            v-model="sourceType"
                                            placeholder="Enter post source type"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Data Source"
                                        label-for="data-source"
                                    >
                                        <v-select
                                            multiple
                                            v-model="dataSourceValue"
                                            name="data_source[]"
                                            id="data-source"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="dataSourceOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Confidence"
                                        label-for="confidence"
                                    >
                                        <v-select
                                            v-model="confidenceValue"
                                            name="confidence[]"
                                            id="confidence"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="confidenceOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="False Positive"
                                        label-for="false-positive"
                                    >
                                        <b-form-input
                                            id="false-positive"
                                            name="false_positive"
                                            v-model="falsePositive"
                                            placeholder="Enter post false positive"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Security Domain"
                                        label-for="security-domain"
                                    >
                                        <v-select
                                            v-model="securityDomainValue"
                                            name="security_domain[]"
                                            id="security-domain"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="securityDomainOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Severity"
                                        label-for="severity"
                                    >
                                        <v-select
                                            v-model="severityValue"
                                            name="severity[]"
                                            id="severity"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="severityOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="CIS 20"
                                        label-for="cis-20"
                                    >
                                        <b-form-tags
                                            v-model="cis20"
                                            id="cis-20"
                                            name="cis_20"
                                            remove-on-delete
                                            placeholder="Add..."
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Kill Chain"
                                        label-for="kill-chain"
                                    >
                                        <v-select
                                            multiple
                                            v-model="killChainValue"
                                            name="kill_chain[]"
                                            id="kill-chain"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="killChainOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="MITRE ATT&CK"
                                        label-for="mitre-att&ck"
                                    >
                                        <v-select
                                            multiple
                                            v-model="mitreAttackValue"
                                            name="mitre_attack[]"
                                            id="mitre-attack"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="mitreAttackOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group label="NIST" label-for="nist">
                                        <b-form-tags
                                            v-model="nist"
                                            name="nist[]"
                                            id="nist"
                                            remove-on-delete
                                            placeholder="Add..."
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Earliest Time"
                                        label-for="earliest-time"
                                    >
                                        <b-form-input
                                            id="earliest-time"
                                            name="earliest_time"
                                            v-model="earliestTime"
                                            placeholder="Earliest Time"
                                        />
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Latest Time"
                                        label-for="latest-time"
                                    >
                                        <b-form-input
                                            id="latest-time"
                                            name="latest_time"
                                            v-model="latestTime"
                                            placeholder="Latest Time"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Cron Schedule"
                                        label-for="cron-schedule"
                                    >
                                        <b-form-input
                                            id="cron-schedule"
                                            name="cron_schedule"
                                            v-model="cronSchedule"
                                            placeholder="Cron Schedule"
                                        />
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Scheduling"
                                        label-for="scheduling"
                                    >
                                        <v-select
                                            v-model="schedulingValue"
                                            name="scheduling[]"
                                            id="scheduling"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="schedulingOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Schedule Window"
                                        label-for="schedule-window"
                                    >
                                        <v-select
                                            v-model="scheduleWindowValue"
                                            name="schedule_window[]"
                                            id="schedule-window"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="scheduleWindowOptions"
                                        />
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Schedule Priority"
                                        label-for="schedule-priority"
                                    >
                                        <v-select
                                            v-model="schedulePriorityValue"
                                            name="schedule_priority[]"
                                            id="schedule-priority"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="schedulePriorityOptions"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Trigger Alert"
                                        label-for="trigger-alert"
                                    >
                                        <v-select
                                            v-model="triggerAlertValue"
                                            name="trigger_alert[]"
                                            id="trigger-alert"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="triggerAlertOptions"
                                            @input="triggerAlertHandler"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row" v-if="!customSelected">
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Trigger Alert Operand"
                                        label-for="trigger_alert_operand"
                                    >
                                        <v-select
                                            v-model="triggerAlertOperandValue"
                                            name="trigger_alert_operand[]"
                                            id="trigger-alert-operand"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="
                                                triggerAlertOperandOptions
                                            "
                                        />
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group
                                        label="Trigger Alert Quantity"
                                        label-for="trigger_alert_quantity"
                                    >
                                        <b-form-input
                                            v-model="triggerAlertQuantityValue"
                                            name="trigger_alert_quantity[]"
                                            id="trigger-alert-quantity"
                                            placeholder="Trigger Alert Quantity"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row" v-if="customSelected">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Trigger Alert Custom Quantity"
                                        label-for="trigger_alert_custom_quantity"
                                    >
                                        <b-form-input
                                            v-model="
                                                triggerAlertCustomQuantityValue
                                            "
                                            name="trigger_alert_custom_quantity[]"
                                            id="trigger-alert-custom-quantity"
                                            placeholder="Trigger Alert Custom Quantity"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Trigger Alert Condition"
                                        label-for="trigger_alert_condition"
                                    >
                                        <v-select
                                            v-model="triggerAlertConditionValue"
                                            name="trigger_alert_condition[]"
                                            id="trigger-alert-condition"
                                            label="name"
                                            placeholder="Choose..."
                                            :options="
                                                triggerAlertConditionOptions
                                            "
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Window Duration"
                                        label-for="window_duration"
                                    >
                                        <b-input-group>
                                            <b-form-input
                                                type="number"
                                                name="window_duration[]"
                                                id="window-duration"
                                                v-model="windowDurationValue"
                                                placeholder="Enter window duration"
                                            />
                                            <b-input-group-append>
                                                <v-select
                                                    v-model="
                                                        windowDurationTypeValue
                                                    "
                                                    name="window_duration_type[]"
                                                    id="window-duration-type"
                                                    label="name"
                                                    placeholder="Choose..."
                                                    :options="
                                                        windowDurationTypeOptions
                                                    "
                                                />
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group
                                        label="Active"
                                        label-for="active"
                                    >
                                        <div class="demo-inline-spacing">
                                            <b-form-radio
                                                v-model="PostActiveValue"
                                                name="post_active"
                                                value="active"
                                                class="
                                                    custom-control-primary
                                                    mt-0
                                                "
                                            >
                                                Active
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="PostActiveValue"
                                                name="post_active"
                                                value="deactive"
                                                class="
                                                    custom-control-primary
                                                    mt-0
                                                "
                                            >
                                                Deactive
                                            </b-form-radio>
                                        </div>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row repeater">
                                <div class="col-md-12">
                                    <!-- attachments -->
                                    <b-form
                                        ref="form"
                                        :style="{ height: trHeight }"
                                        class="repeater-form"
                                        @submit.prevent="repeateAgain"
                                    >
                                        <b-row
                                            v-for="(item, index) in items"
                                            :id="item.id"
                                            :key="item.id"
                                            ref="row"
                                        >
                                            <b-col md="6">
                                                <b-form-group
                                                    label="Attachments"
                                                    label-for="attachments"
                                                >
                                                    <div
                                                        class="d-flex flex-row"
                                                    >
                                                        <b-form-file
                                                            placeholder="Choose File"
                                                            @change="
                                                                uploadFileHandler(
                                                                    $event,
                                                                    'attachment',
                                                                    item.id
                                                                )
                                                            "
                                                            drop-placeholder="Drop it"
                                                        />
                                                    </div>
                                                    <small v-if="item.media">
                                                        <strong
                                                            >File Name: </strong
                                                        >{{
                                                            item.media.filename
                                                        }}
                                                    </small>
                                                </b-form-group>
                                            </b-col>
                                            <b-col lg="2" md="3" class="mb-1">
                                                <b-button
                                                    v-ripple.400="
                                                        'rgba(234, 84, 85, 0.15)'
                                                    "
                                                    variant="outline-danger"
                                                    class="mt-0 mt-md-2"
                                                    @click="removeItem(index)"
                                                >
                                                    <feather-icon
                                                        icon="XIcon"
                                                        class="mr-25"
                                                    />
                                                    <span>Delete</span>
                                                </b-button>
                                            </b-col>
                                            <b-col cols="12">
                                                <hr />
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                    <!-- attachments -->
                                    <!-- add new attachment row -->
                                    <b-button
                                        class="btn-icon"
                                        v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                        "
                                        variant="outline-success"
                                        @click="repeateAgain"
                                    >
                                        <feather-icon icon="PlusIcon" />
                                    </b-button>
                                    <!-- add new attachment row -->
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <b-button
                                        type="submit"
                                        variant="primary"
                                        :disabled="submitted"
                                    >
                                        <b-spinner
                                            v-if="submitted"
                                            class="mr-25"
                                            small
                                        />
                                        Update Post
                                    </b-button>
                                </div>
                                <div class="offset-md-7"></div>
                            </div>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import { heightFade } from "@core/directives/animations";
import { heightTransition } from "@core/mixins/ui/transition";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AlertComponent from "@/components/AlertComponent";
import _ from "lodash";
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BButton,
    BAlert,
    BImg,
    BFormTextarea,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BFormRadio,
    BFormSelect,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios";

export default {
    directives: {
        Ripple,
        "height-fade": heightFade,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BFormGroup,
        BFormFile,
        BFormInput,
        BForm,
        BFormSelect,
        BButton,
        BAlert,
        BImg,
        BFormTextarea,
        BFormTags,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        quillEditor,
        BInputGroup,
        BInputGroupAppend,
        BFormRadio,

        AlertComponent,
    },
    mixins: [heightTransition],
    data() {
        return {
            // submit flag
            submitted: false,
            admins:[],
            // post parameters
            authorId: null,
            title: undefined,
            description: undefined,
            spl: undefined,
            tags: [],
            version: undefined,
            reference: undefined,
            securityJourneyValue: [],
            securityJourneyOptions: [],
            securityUseCaseApplicabilityValue: [],
            securityUseCaseApplicabilityOptions: [],
            dataModelsValue: [],
            dataModelsOptions: [],
            lookup: [],
            lookupId: undefined,
            sourceType: undefined,
            dataSourceValue: [],
            dataSourceOptions: [],
            confidenceValue: undefined,
            confidenceOptions: [],
            falsePositive: undefined,
            securityDomainValue: undefined,
            securityDomainOptions: [],
            severityValue: undefined,
            severityOptions: [],
            cis20: [],
            cis20Id: undefined,
            killChainValue: [],
            killChainOptions: [],
            mitreAttackValue: [],
            mitreAttackOptions: [],
            nist: [],
            nistId: undefined,
            earliestTime: undefined,
            earliestTimeId: undefined,
            latestTime: undefined,
            latestTimeId: undefined,
            cronSchedule: undefined,
            cronScheduleId: undefined,
            schedulingValue: undefined,
            schedulingOptions: [],
            schedulePriorityValue: undefined,
            schedulePriorityOptions: [],
            scheduleWindowValue: undefined,
            scheduleWindowOptions: [],
            triggerAlertValue: undefined,
            triggerAlertOptions: [],
            triggerAlertOperandValue: undefined,
            triggerAlertOperandOptions: [],
            triggerAlertQuantityValue: undefined,
            triggerAlertQuantity: undefined,
            triggerAlertCustomQuantityValue: undefined,
            triggerAlertCustomQuantityId: undefined,
            customSelected: false,
            triggerAlertConditionValue: undefined,
            triggerAlertConditionOptions: [],
            windowDurationValue: undefined,
            windowDurationId: undefined,
            windowDurationTypeValue: undefined,
            windowDurationTypeOptions: [],
            windowDurationTypes: [
                {
                    name: "seconds",
                    coefficient: 1,
                },
                {
                    name: "minutes",
                    coefficient: 60,
                },
                {
                    name: "hours",
                    coefficient: 3600,
                },
                {
                    name: "days",
                    coefficient: 86400,
                },
                {
                    name: "weeks",
                    coefficient: 604800,
                },
                {
                    name: "years",
                    coefficient: 31536000,
                },
            ],
            PostActiveValue: "deactive",
            // validation rules
            required,
            // quill editor options
            editorOption: {
                placeholder: "Enter post body",
                theme: "snow",
            },
            // featured image
            featuredImage: {
                id: undefined,
                preview: undefined,
            },
            // repeater
            items: [
                {
                    id: 1,
                    attachment: undefined,
                    preview: undefined,
                },
            ],
            nextAttachId: 2,
            // error handling
            validationErrors: [],
        };
    },
    mounted() {
        this.initTrHeight();
    },
    created() {
        window.addEventListener("resize", this.initTrHeight);
        // get post properties (attributes and values)
        axios
            .get("https://api.soclib.net/api/attributes", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                this.securityJourneyOptions =
                    response.data.security_journey[0].values;
                this.securityUseCaseApplicabilityOptions =
                    response.data.security_use_case_applicability[0].values;
                this.dataModelsOptions = response.data.data_models[0].values;
                this.lookupId = response.data.lookup[0].id;
                this.dataSourceOptions = response.data.data_source[0].values;
                this.confidenceOptions = response.data.confidence[0].values;
                this.securityDomainOptions =
                    response.data.security_domain[0].values;
                this.severityOptions = response.data.severity[0].values;
                this.cis20Id = response.data.cis_20[0].id;
                this.killChainOptions = response.data.kill_chain[0].values;
                this.mitreAttackId = response.data.mitre_attack[0].id;
                this.nistId = response.data.nist[0].id;
                this.earliestTimeId = response.data.earliest_time[0].id;
                this.latestTimeId = response.data.latest_time[0].id;
                this.cronScheduleId = response.data.cron_schedule[0].id;
                this.schedulingOptions = response.data.scheduling[0].values;
                this.schedulePriorityOptions =
                    response.data.schedule_priority[0].values;
                this.scheduleWindowOptions =
                    response.data.schedule_window[0].values;
                this.triggerAlertOptions =
                    response.data.trigger_alert[0].values;
                this.triggerAlertOperandOptions =
                    response.data.trigger_alert_operand[0].values;
                this.triggerAlertQuantityId =
                    response.data.trigger_alert_quantity[0].id;
                this.triggerAlertCustomQuantityId =
                    response.data.trigger_alert_custom_quantity[0].id;
                this.triggerAlertConditionOptions =
                    response.data.trigger_alert_condition[0].values;
                this.windowDurationId = response.data.window_duration[0].id;
                this.windowDurationTypeOptions =
                    response.data.window_duration_type[0].values;
                if (this.windowDurationTypeValue == undefined) {
                    this.windowDurationTypeValue =
                        this.windowDurationTypeOptions[0];
                }
            });

        // get techniques
        axios
            .get("https://api.soclib.net/api/techniques", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                const mitreAttackValues = _.map(response.data, (item) => {
                    return _.omit(
                        { ...item, name: item.technique_id, attribute_id: 11 },
                        "technique_id"
                    );
                });
                this.mitreAttackOptions = mitreAttackValues;
            })
            .catch((error) => {});
        // find post
        const postId = this.$route.params.id;
        (async () => {
            const triggerAlert = await this.getPost(postId);
            if (triggerAlert) {
                if (triggerAlert.name == "custom") {
                    this.customSelected = true;
                } else {
                    this.customSelected = false;
                }
            }
        })();
        this.getAdmins();
    },
    destroyed() {
        window.removeEventListener("resize", this.initTrHeight);
    },
    methods: {
        // add attachment row
        repeateAgain() {
            this.items.push({
                id: (this.nextAttachId += this.nextAttachId),
                attachment: undefined,
                preview: undefined,
            });

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
            });
        },
        // remove attachment row
        removeItem(index) {
            this.items.splice(index, 1);
            this.trTrimHeight(this.$refs.row[0].offsetHeight);
        },
        // set initial height of attachment rows
        initTrHeight() {
            this.trSetHeight(null);
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight);
            });
        },
        /*
        showAlert(text, type, duration = 60) {
            this.alert.body = text;
            this.alert.type = type;
            this.alert.duration = duration;
        },
        */
        // init quil editor
        onEditorReady(editor) {
            editor
                .getModule("toolbar")
                .addHandler("image", () => this.imageHandler(editor));
        },
        // quil editor image handler
        imageHandler: async function (editor) {
            const input = document.createElement("input");

            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();

            input.onchange = async () => {
                const range = editor.getSelection(true);
                const file = input.files[0];
                const formData = new FormData();
                formData.append("media", file);

                await axios
                    .post("https://api.soclib.net/api/media", formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "accessToken"
                            )}`,
                        },
                    })
                    .then(async ({ data }) => {
                        await editor.insertEmbed(
                            range.index,
                            "image",
                            `http://api.soclib.net/storage/${data.directory}/${data.filename}.${data.extension}`
                        );
                    })
                    .catch((error) => {});
            };
        },
        // remove featured image (only on client-side)
        removeFeaturedImage() {
            this.$refs["featured-image"].reset();
            this.featuredImage.preview = undefined;
            this.featuredImage.id = undefined;
        },
        // upload file (attachments and featured image)
        uploadFileHandler(e, type = null, id = null) {
            let formData = new FormData();
            formData.append("media", e.target.files[0]);

            axios
                .post("https://api.soclib.net/api/media", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    switch (type) {
                        case "attachment":
                            const item = this.items.findIndex(
                                (item) => item.id == id
                            );
                            this.items[item].attachment = response.data.id;
                            this.items[item].preview = URL.createObjectURL(
                                e.target.files[0]
                            );
                            break;
                        case "featured_image":
                            this.featuredImage.id = response.data.id;
                            this.featuredImage.preview = URL.createObjectURL(
                                e.target.files[0]
                            );
                            break;
                    }
                })
                .catch((error) => {});
        },
        // remove all empty, undefined and null values from object
        removeEmpty(obj) {
            let finalObj = {};
            Object.keys(obj).forEach((key) => {
                if (obj[key] && typeof obj[key] === "object") {
                    const nestedObj = this.removeEmpty(obj[key]);
                    if (Object.keys(nestedObj).length) {
                        finalObj[key] = nestedObj;
                    }
                } else if (
                    obj[key] !== "" &&
                    obj[key] !== undefined &&
                    obj[key] !== null
                ) {
                    finalObj[key] = obj[key];
                }
            });
            return finalObj;
        },
        // update post with new parameters
        updatePostHandler() {
            // validate editPostForm form
            this.$refs.editPostForm.validate().then((success) => {
                if (success) {
                    this.submitted = true;
                    // take post id from url
                    const postId = this.$route.params.id;
                    // transform lookup field
                    const lookupId = this.lookupId;
                    const lookup = _.mapValues(this.lookup, function (item) {
                        return {
                            attribute_id: lookupId,
                            name: item,
                        };
                    });
                    // transform cis20 field
                    const cis20Id = this.cis20Id;
                    const cis_20 = _.mapValues(this.cis20, function (_item) {
                        return {
                            attribute_id: cis20Id,
                            name: _item,
                        };
                    });
                    // transform nist field
                    const nistId = this.nistId;
                    const nist = _.mapValues(this.nist, function (__item) {
                        return {
                            attribute_id: nistId,
                            name: __item,
                        };
                    });
                    const earliestTimeId = this.earliestTimeId;
                    const latestTimeId = this.latestTimeId;
                    const cronScheduleId = this.cronScheduleId;
                    const triggerAlertQuantityId = this.triggerAlertQuantityId;
                    const triggerAlertCustomQuantityId =
                        this.triggerAlertCustomQuantityId;
                    const windowDurationId = this.windowDurationId;
                    // remove all empty values from parameters
                    const params = this.removeEmpty({
                        author_id: this.authorId,
                        title: this.title,
                        description: this.description,
                        featured_image:
                            this.featuredImage.id != undefined
                                ? this.featuredImage.id
                                : null,
                        attachments: _.map(this.items, "attachment"),
                        spl: this.spl,
                        tags: this.tags,
                        source_type: this.sourceType,
                        false_positive: this.falsePositive,
                        version: this.version,
                        reference: this.reference,
                        security_journey: this.securityJourneyValue,
                        security_use_case_applicability:
                            this.securityUseCaseApplicabilityValue,
                        data_models: this.dataModelsValue,
                        data_source: this.dataSourceValue,
                        confidence: [this.confidenceValue],
                        security_domain: [this.securityDomainValue],
                        severity: [this.severityValue],
                        kill_chain: this.killChainValue,
                        lookup,
                        cis_20,
                        nist,
                        mitre_attack: this.mitreAttackValue,
                        earliest_time: [
                            {
                                attribute_id: this.earliestTime
                                    ? earliestTimeId
                                    : undefined,
                                name: this.earliestTime,
                            },
                        ],
                        latest_time: [
                            {
                                attribute_id: this.latestTime
                                    ? latestTimeId
                                    : undefined,
                                name: this.latestTime,
                            },
                        ],
                        cron_schedule: [
                            {
                                attribute_id: this.cronSchedule
                                    ? cronScheduleId
                                    : undefined,
                                name: this.cronSchedule,
                            },
                        ],
                        scheduling: [this.schedulingValue],
                        schedule_window: [this.scheduleWindowValue],
                        schedule_priority: [this.schedulePriorityValue],
                        trigger_alert: [this.triggerAlertValue],
                        trigger_alert_operand: [this.triggerAlertOperandValue],
                        trigger_alert_quantity: [
                            {
                                attribute_id: this.triggerAlertQuantityValue
                                    ? triggerAlertQuantityId
                                    : undefined,
                                name: this.triggerAlertQuantityValue,
                            },
                        ],
                        trigger_alert_custom_quantity: [
                            {
                                attribute_id: this
                                    .triggerAlertCustomQuantityValue
                                    ? triggerAlertCustomQuantityId
                                    : undefined,
                                name: this.triggerAlertCustomQuantityValue,
                            },
                        ],
                        trigger_alert_condition: [
                            this.triggerAlertConditionValue,
                        ],
                        window_duration:
                            this.windowDurationValue &&
                            this.windowDurationTypeValue
                                ? [
                                      {
                                          attribute_id:
                                              this.windowDurationValue &&
                                              this.windowDurationTypeValue
                                                  ? windowDurationId
                                                  : undefined,
                                          name: this.windowDurationTypeValue
                                              ? this.converDuration(
                                                    this.windowDurationValue,
                                                    this.windowDurationTypeValue
                                                        .name
                                                )
                                              : undefined,
                                      },
                                  ]
                                : [],
                        window_duration_type: this.windowDurationValue
                            ? [this.windowDurationTypeValue]
                            : undefined,
                        post_active: this.PostActiveValue,
                    });

                    axiosIns
                        .patch(
                            `https://api.soclib.net/api/posts/${postId}`,
                            params,
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem(
                                        "accessToken"
                                    )}`,
                                },
                            }
                        )
                        .then((response) => {
                            this.submitted = false;
                            // set alert parameters
                            this.$store.commit("posts/setAlert", {
                                text: "Post info updated successfully",
                                type: "success",
                                visible: true,
                                count: 10,
                            });
                            // navigate to post list page
                            return this.$router.push("/dashboard/posts");
                        })
                        .catch((error) => {
                            this.submitted = false;
                            this.validationErrors = _.flatten(
                                Object.values(error.response.data)
                            );
                        });
                }
            });
        },
        getPost(id) {
            return axios
                .get(`https://api.soclib.net/api/posts/${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    const properties = this.groupBy(
                        response.data.values,
                        "name"
                    );
                    this.authorId = response.data.author.id;
                    this.title = response.data.title;
                    this.description = response.data.description;
                    this.tags = _.map(response.data.tags, "name.en");
                    this.spl = response.data.spl;
                    this.reference = response.data.reference;
                    this.version = response.data.version;
                    this.sourceType = response.data.source_type;
                    this.falsePositive = response.data.false_positive;
                    this.featuredImage.preview = response.data.featured_image
                        ? response.data.featured_image.url
                        : undefined;
                    this.featuredImage.id = response.data.featured_image
                        ? response.data.featured_image.media.id
                        : undefined;
                    if (response.data.attachments != undefined) {
                        this.items = response.data.attachments;
                        // set initial height of attachment rows
                        this.$nextTick(() => {
                            this.trAddHeight(
                                this.$refs.row[0].offsetHeight *
                                    (this.items.length - 1)
                            );
                        });
                    }
                    this.PostActiveValue = response.data.status;
                    this.securityJourneyValue = properties.security_journey;
                    this.securityUseCaseApplicabilityValue =
                        properties.security_use_case_applicability;
                    this.dataModelsValue = properties.data_models;
                    this.lookup =
                        properties.lookup != undefined
                            ? _.map(properties.lookup, "name")
                            : undefined;
                    this.dataSourceValue = properties.data_source;
                    this.confidenceValue = _.head(properties.confidence);
                    this.securityDomainValue = _.head(
                        properties.security_domain
                    );
                    this.severityValue = _.head(properties.severity);
                    this.cis20 =
                        properties.cis_20 != undefined
                            ? _.map(properties.cis_20, "name")
                            : undefined;
                    this.killChainValue = properties.kill_chain;
                    this.mitreAttackValue = _.map(
                        properties.mitre_attack,
                        (item) => {
                            return _.find(this.mitreAttackOptions, {
                                name: item.name,
                            });
                        }
                    );
                    this.nist =
                        properties.nist != undefined
                            ? _.map(properties.nist, "name")
                            : undefined;
                    this.earliestTime =
                        properties.earliest_time != undefined
                            ? _.map(properties.earliest_time, "name")[0]
                            : undefined;
                    this.latestTime =
                        properties.latest_time != undefined
                            ? _.map(properties.latest_time, "name")[0]
                            : undefined;
                    this.cronSchedule =
                        properties.cron_schedule != undefined
                            ? _.map(properties.cron_schedule, "name")[0]
                            : undefined;
                    this.schedulingValue = _.head(properties.scheduling);
                    this.scheduleWindowValue = _.head(
                        properties.schedule_window
                    );
                    this.schedulePriorityValue = _.head(
                        properties.schedule_priority
                    );
                    this.triggerAlertValue = _.head(properties.trigger_alert);
                    this.triggerAlertOperandValue = _.head(
                        properties.trigger_alert_operand
                    );
                    this.triggerAlertQuantityValue =
                        properties.trigger_alert_quantity != undefined
                            ? _.map(
                                  properties.trigger_alert_quantity,
                                  "name"
                              )[0]
                            : undefined;
                    this.triggerAlertCustomQuantityValue =
                        properties.trigger_alert_custom_quantity != undefined
                            ? _.map(
                                  properties.trigger_alert_custom_quantity,
                                  "name"
                              )[0]
                            : undefined;
                    this.triggerAlertConditionValue = _.head(
                        properties.trigger_alert_condition
                    );
                    this.windowDurationTypeValue = _.head(
                        properties.window_duration_type
                    );
                    this.windowDurationValue =
                        properties.window_duration != undefined
                            ? this.divDuration(
                                  _.map(properties.window_duration, "name")[0],
                                  this.windowDurationTypeValue.name
                              )
                            : undefined;

                    return this.triggerAlertValue;
                });
        },
        // grouped the values (properties) by attribute name
        groupBy(list, groupable) {
            return list.reduce((groups, item) => {
                const group = groups[item.attribute[groupable]] || [];
                group.push(item);
                groups[item.attribute[groupable]] = group;
                return groups;
            }, {});
        },
        triggerAlertHandler() {
            const triggerAlertName = this.triggerAlertValue.name;

            this.triggerAlertQuantityValue = undefined;
            this.triggerAlertOperandValue = undefined;
            this.triggerAlertCustomQuantityValue = undefined;

            if (triggerAlertName == "custom") {
                this.customSelected = true;
            } else {
                this.customSelected = false;
            }
        },
        converDuration(value, name) {
            const typeIndex = this.windowDurationTypes.findIndex(
                (item) => item.name == name
            );
            return value * this.windowDurationTypes[typeIndex].coefficient;
        },
        divDuration(value, name) {
            const typeIndex = this.windowDurationTypes.findIndex(
                (item) => item.name == name
            );
            return value / this.windowDurationTypes[typeIndex].coefficient;
        },

        /* Gets the list of admins */
        getAdmins(_params) {
            axios
                .get("https://api.soclib.net/api/users/admins/list", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                    params: {
                        ..._params,
                    },
                })
                .then((response) => {
                    this.admins = response.data;
                })
                .catch((error) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: 0.35s height;
}
#window-duration-type {
    width: 150px !important;
}
</style>
